@import "../../base/mixins.scss";
@import "../../base/constants.scss";

.complete-signup-screen {
	background-color: var(--clr-gray-200);
	@include set-flex(center, center);
	@include fill-height;

	h2 {
		font-weight: 600;
		font-size: 1.75rem;
	}
}

.complete-signup-section {
	@include set-flex(normal, space-between, column);
	max-width: 24rem;
	width: 90%;
	gap: 1rem;
}
