@import "../../base/mixins.scss";
@import "../../base/constants.scss";

.app-wrapper {
	display: flex;
	overflow: hidden;
	@include fill-height;
}

.app-content {
	min-width: 100vw;
	overflow: hidden;
	white-space: nowrap;
	--drawer-width: 20rem;
	transition: transform 500ms ease-in-out, min-width 500ms ease-in-out;
	transform: translateX(calc(var(--drawer-width) * -1));
}

.navigation.open ~ .app-content {
	min-width: calc(100vw - var(--drawer-width));
	transform: translateX(0);
}
