@import "../../../base/mixins.scss";

.button--primary {
	@include reset-button;
	width: 100%;
	padding: 1rem;
	display: block;
	color: white;
	text-decoration: none !important;
	text-align: center;
	background-color: var(--clr-primary);
	border-radius: var(--border-radius-400);
}
