// Font Imports
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-LightItalic.otf") format("opentype");
	font-weight: lighter;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Light.otf") format("opentype");
	font-weight: lighter;
	font-style: italic;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Regular.otf") format("opentype");
	font-weight: normal;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-RegItalic.otf") format("opentype");
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Semibold.otf") format("opentype");
	font-weight: 600;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Bold.otf") format("opentype");
	font-weight: bold;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Bolditalic.otf") format("opentype");
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Extrabold.otf") format("opentype");
	font-weight: 800;
}
@font-face {
	font-family: "Proxima Nova";
	src: url("../assets/fonts/Proxima-Nova-Black.otf") format("opentype");
	font-weight: 900;
}

// Default Typography
:root {
	font-family: "Proxima Nova";
}

a {
	color: var(--clr-gray-700);
	text-decoration: underline !important;
}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.25rem;
}

h3 {
	font-size: 2rem;
}

h4 {
	font-size: 1.5rem;
}

button,
a.button,
input[type="submit"] {
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.03em;
}
