@import "../../base/mixins.scss";

.error-message {
	gap: 0.25rem;
	width: 100%;
	display: flex;
	padding: 0.35rem;
	font-size: 0.8rem;
	color: var(--clr-red-900);
	background-color: var(--clr-red-500);
	border-radius: var(--border-radius-400);

	&__icon {
		transform: scale(0.6);
		path {
			stroke: var(--clr-red-900);
		}
	}

	&__text {
		margin-top: 0.15rem;
	}
}
