@import "../../base/mixins.scss";

.modal {
	width: 100vw;
	@include fill-height;
	@include set-flex(center, center, column);
	position: absolute;
	inset: 0;
	background-color: var(--clr-modal-bg);
	z-index: 2;

	&__inner {
		max-width: 90%;
		position: relative;
		text-align: center;
		background-color: white;
		border-radius: 1rem;
	}

	.button--close {
		background-color: var(--clr-gray-400);

		&:hover {
			background-color: var(--clr-gray-300) !important;
		}
	}
}
