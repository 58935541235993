@import "../../../base/mixins.scss";

.upload-model {
	&__label {
		@include set-flex(center, space-between, column);
		border: 2px dashed var(--clr-gray-600);
		padding: 2rem;
		gap: 1rem;

		input {
			display: none;
		}
	}
}
