@import "../../../base/mixins.scss";

.button--secondary {
	@include reset-button;
	width: 100%;
	padding: 1rem;
	color: var(--clr-gray-700);
	border: 2px solid var(--clr-gray-700);
	border-radius: var(--border-radius-400);
}
