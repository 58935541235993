@import "../../base/mixins.scss";
@import "../../base/constants.scss";

.drawer {
	--transition-duration: 500ms;
	transition: transform var(--transition-duration) ease-in-out,
		visibility 0ms ease-in-out var(--transition-duration),
		padding 0ms ease-in-out var(--transition-duration);

	background-color: var(--clr-gray-200);
	transform: translateX(-20rem);
	white-space: nowrap;
	overflow-y: scroll;
	visibility: hidden;
	padding: 2rem 1rem;
	width: 20rem;
	flex-shrink: 0;

	ul {
		gap: 0.5rem;
		@include reset-list;
		display: flex;
		flex-direction: column;
	}

	&.open {
		visibility: visible;
		transform: translateX(0rem);
		transition: transform var(--transition-duration) ease-in-out,
			visibility var(--transition-duration) ease-in-out 0ms;
	}

	&__toggle {
		top: 1rem;
		right: 1rem;
		padding: 1rem;
		z-index: 1;
		position: fixed;
	}
}
