@import "../../base/mixins.scss";
@import "../../base/constants.scss";

.login-screen {
	@include fill-height;
	@include set-flex(center, center);
	background-color: var(--clr-gray-200);
	text-align: center;
	width: 100%;

	.login-section {
		width: 100%;
		max-width: 28rem;
		padding-inline: 2rem;
	}
}

// Tablet + desktop viewports
@media only screen and (min-width: $break-tablet) {
	.login-screen {
		justify-content: unset;

		.login-section {
			width: unset;
			padding: 3rem;
			max-width: 28rem;
			width: 45%;
		}

		.hero-section {
			height: 100%;
			flex-grow: 1;
			position: relative;
			background: radial-gradient(var(--clr-gray-400), var(--clr-gray-200));
		}
	}
}
