@import "../../../base/mixins.scss";

.text-input--password .text-input__input {
	padding-inline-end: 2.85rem;
}

.toggle-show-password {
	@include reset-button;
	@include abs-center-y;
	font-size: 0.8rem;
	font-weight: 600;
	padding-block: 0.2rem;
	right: 1rem;

	svg {
		width: 1.2rem;
		path {
			stroke: var(--clr-gray-700);
		}
	}

	&:hover {
		svg path {
			fill: var(--clr-gray-800);
		}
	}
}
