@import "../../../base/mixins.scss";

.back-button {
	gap: 0.5rem;
	position: fixed;
	--inset: 1.5rem;
	@include reset-anchor;
	font-weight: 600;
	@include set-flex(center, space-between);
	inset: var(--inset) auto auto var(--inset);
}
