@import "../base/mixins.scss";

.auth-form {
	@include set-flex(flex-start, space-between, column);
	gap: 1rem;

	&__text {
		width: 100%;
	}
}
