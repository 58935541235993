@import "../../base/mixins.scss";

.navigation {
	gap: 1rem;
	display: flex;
	flex-direction: column;

	.logo {
		text-align: center;
	}

	&__bottom {
		padding-top: 3rem !important;
		margin-top: auto;
	}
}
