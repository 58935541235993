@import "../../../base/mixins.scss";
@import "../../../base/constants.scss";

.text-input {
	width: 100%;
	display: block;
	height: min-content;

	&__inner {
		position: relative;
	}

	&__placeholder {
		@include abs-center-y;
		transition-property: opacity, top;
		transition: 500ms ease;
		color: var(--clr-gray-600);
		left: 1rem;

		&:hover {
			cursor: text;
		}
	}

	&__input {
		@include reset-input;
		border-radius: var(--border-radius-400);
		border: 1px solid var(--clr-gray-300);
		background-color: white;
		padding: 1rem;
		width: 100%;
	}

	.error-message {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

// Focus state
.text-input__input:focus,
.text-input__input:not(:placeholder-shown) {
	& + .text-input__placeholder {
		top: -0.5rem;
		opacity: 0;
	}
}
