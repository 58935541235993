@import "../../../base/constants.scss";
@import "../../../base/mixins.scss";

.drawer-item {
	// Reset button style
	@include reset-button;
	font-weight: unset;
	text-transform: none;
	letter-spacing: unset;

	width: 100%;
	gap: 1rem;
	user-select: none;
	font-size: 1.15rem;
	padding: 0.5rem 1rem;
	color: var(--clr-gray-700);
	text-decoration: none !important;
	@include set-flex(center, flex-start);
	border-radius: var(--border-radius-500);

	svg {
		min-width: 1.35rem;
		width: 1.35rem;

		path {
			stroke: var(--clr-gray-700);
		}
	}
}

// Hover state
@media (hover: hover) {
	.drawer-item:hover {
		background-color: var(--clr-gray-300);
		cursor: pointer;
	}
}

// Active state
.drawer-item.active {
	background-color: var(--clr-gray-300);
}
