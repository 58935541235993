:root {
	--clr-primary: hsla(24, 100%, 50%, 1);
	--clr-secondary: hsla(229, 6%, 35%, 1);
	--clr-links: hsla(204, 97%, 54%, 1);
	--clr-disabled: hsla(36, 2%, 58%, 1);
	--clr-modal-bg: hsla(240, 2%, 36%, 0.5);

	--clr-gray-700: hsla(240, 2%, 57%, 1);
	--clr-gray-600: hsla(240, 3%, 69%, 1);
	--clr-gray-500: hsla(240, 5%, 79%, 1);
	--clr-gray-400: hsla(240, 6%, 83%, 1);
	--clr-gray-300: hsla(240, 11%, 91%, 1);
	--clr-gray-200: hsla(240, 24%, 96%, 1);

	--clr-green-900: hsla(134, 61%, 24%, 1);
	--clr-green-700: hsla(134, 61%, 41%, 1);
	--clr-green-500: hsla(134, 41%, 65%, 1);
	--clr-green-300: hsla(135, 42%, 91%, 1);
	--clr-green-200: hsla(132, 42%, 95%, 1);

	--clr-yellow-900: hsla(49, 85%, 36%, 1);
	--clr-yellow-700: hsla(49, 85%, 45%, 1);
	--clr-yellow-500: hsla(49, 69%, 67%, 1);
	--clr-yellow-300: hsla(49, 69%, 80%, 1);
	--clr-yellow-200: hsla(50, 68%, 91%, 1);

	--clr-red-900: hsla(349, 100%, 28%, 1);
	--clr-red-700: hsla(349, 100%, 46%, 1);
	--clr-red-500: hsla(350, 52%, 76%, 1);
	--clr-red-300: hsla(349, 54%, 90%, 1);
	--clr-red-200: hsla(351, 54%, 95%, 1);

	--border-radius-400: 0.3rem;
	--border-radius-500: 0.75rem;
	--border-radius-600: 1.5rem;
	--border-radius-700: 3rem;

	--shadow-sm-1: 0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.08);
	--shadow-sm-2: 0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.1),
		0px 6px 12px rgba(14, 31, 53, 0.08);

	--shadow-md-1: 0px 3px 6px rgba(14, 31, 53, 0.08), 0px 6px 12px rgba(14, 31, 53, 0.12),
		0px 14px 24px rgba(14, 31, 53, 0.08);
	--shadow-md-2: 0px 4px 8px rgba(14, 31, 53, 0.1), 0px 8px 16px rgba(14, 31, 53, 0.16),
		0px 16px 28px -1px rgba(14, 31, 53, 0.1);

	--shadow-lg-1: 0px 5px 10px rgba(14, 31, 53, 0.06), 0px 10px 20px rgba(14, 31, 53, 0.12),
		0px 16px 24px -1px rgba(14, 31, 53, 0.12), 0px 20px 38px -2px rgba(14, 31, 53, 0.06);
	--shadow-lg-2: 0px 5px 10px rgba(14, 31, 53, 0.08), 0px 10px 20px rgba(14, 31, 53, 0.16),
		0px 24px 32px -1px rgba(14, 31, 53, 0.16), 0px 32px 64px -2px rgba(14, 31, 53, 0.08);
}

$break-tablet: 48em;
$break-desktop: 84em;
